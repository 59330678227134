/* You can add global styles to this file, and also import other style files */
/* @import "~bootstrap/dist/css/bootstrap.css"; */

html, body {
  width: 100%;
  height: 100%;
}
html {
  background: linear-gradient(180deg, #FFA4D4 0%, #A4A4F4 100%);
  /* background: tan; */
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.token {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  display: inline-block;
  background-color: white;
}

h1, h3 {
  margin: 0;
}

h1 {
  font-family: 'Monument Black';
}

h3 {
  font-family: 'Patrick Hand';
}

@font-face {
  font-family: 'Monument Regular';
  src: url('../assets/fonts/monument-extended/MonumentExtended-Regular.woff2') format('woff2'),
       url('../assets/fonts/monument-extended/MonumentExtended-Regular.woff') format('woff');
}

@font-face {
   font-family: 'Monument Black';
   src: url('../assets/fonts/monument-extended/MonumentExtended-Black.woff2') format('woff2'),
        url('../assets/fonts/monument-extended/MonumentExtended-Black.woff') format('woff');
 }
